<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import PageHeader from "@/components/page-header";


import animationData from "@/components/widgets/nkmsrxys.json";
import animationData1 from "@/components/widgets/gsqxdxog.json";

import serverConfig   from '@/helpers/config';
import Swal from "sweetalert2";
import axios from 'axios';
import { ref } from "vue";


import { mapActions} from "vuex";
import moment from 'moment';

import { jsPDF } from "jspdf";


export default {
  page: {
    title: "Sommario ordine",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };

    let tk = 'Bearer '+localStorage.getItem('tk')

    return { active, toggleActive,tk };
  },
  components: {
    Layout,
    PageHeader,
    
  },
  data() {
    return {
      UrlServer: serverConfig.EP,
      datastore: this.$store.state,
      title: "Dettaglio ordine",
      data:{
        status:'completed'
      },

      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Ordini",
          href: "/ecommerce/orders",
        },
        {
          text: "Dettaglio ordine",
          active: true,
        },
      ],
      taxRate: 0.125,
      shippingRate: 65.0,
      discountRate: 0.15,
      quantity: "",
      currencySign: "$",
      defaultOptions: { animationData: animationData },
      defaultOptions1: { animationData: animationData1 },
      value: 2,
      value1: 1,
      value2: 1,
      orderList:[],

    };
  },

  mounted() {
    this.getCart()
    setTimeout(() => {
      this.isData();
    }, 100);
  },
  methods: {
    ...mapActions('auth', ['getSalesRoute', ]),
    ...mapActions('ecommercesettings', ['addToCart','updateCart', 'getCartByOrd','deleteItem']),
    convertFromUnix(value){
      return  moment.unix(value).locale('it').format("LLL")
    },
    checkVar(value){
      if (value) {
        return typeof value
      }
    },
    convertHTMLToPDF() {
        var doc = new jsPDF('p', 'pt', 'A4');
        var pdfjs = document.querySelector('#content-area');
      // Convert HTML invoice template source into PDF in JavaScript   
      doc.html(pdfjs, {
      callback: function(doc) {
      doc.save("output.pdf");
      },
      margin: [72, 72, 72, 72],
              autoPaging: 'text',
              html2canvas: {
                  allowTaint: true,
                  dpi: 300,
                  letterRendering: true,
                  logging: false,
                  scale: .8
              }
      });
    },
    generatePdf(){
      console.log(this.$refs)
       var doc = new jsPDF('p', 'pt', 'A4');
      
        const opt = {
        callback: function (jsPdf) {
            jsPdf.save("Test.pdf");
            // to open the generated PDF in browser window
            // window.open(jsPdf.output('bloburl'));
        },
        margin: [72, 72, 72, 72],
        autoPaging: 'text',
        html2canvas: {
            allowTaint: true,
            dpi: 300,
            letterRendering: true,
            logging: false,
            scale: .8
        }
      };
      doc.html("#testHtml",opt);
    
    },
    formatPrice(value) {
      let val = (value/1).toFixed(2).replace('.',',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getCart(){
      if ( this.$route.params.orx) {
        axios.get(`${this.UrlServer}carts/completed/${this.$route.params.orx}`, 
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
              this.orderList = response.data
          }).catch((error) => {
              if (error == "Error: Request failed with status code 401")
              {
                // this.alertSessionExp()
              } else {
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }

        });
      }
    },
    updateCompleteCart(data){
      if ( this.$route.params.orx) {
        axios.get(`${this.UrlServer}carts/update-cart`, 
        { 
          params:data, 
          headers: { 'authorization': 'Bearer '+ localStorage.getItem('tk')}
        }
       ).then((response)=>{
            if (response.data){
                if (response.data.status){
                    if (response.data.status=='Ok'){

                        Swal.fire({
                            title:"Prodotto selezionato",
                            text:"Il prodotto selezionato è stato aggiunto con successo alla tua lista!",
                            icon:"success",
                            showCancelButton:false,
                            showConfirmButton:false,
                            timer:1000,
                        })

                        this.getCart()
                    } else {
                       
                        if (response.data.status=='Error-uuid'){
                            Swal.fire({
                                title:"Errore di identificazione",
                                text:"Mi spiace, ma il codice identificativo dell'ordine risulta sbagliato. Se il problema persiste. Effettua il logout dal sistema e riprova.",
                                icon:"error",
                                showCancelButton:false,
                                showConfirmButton:false,
                                timer:5000,
                            })
                        }
    
                    }
                } else {
                    Swal.fire({
                        title:"Selezione prodotto fallita",
                        text:"Mi spiace, non è possibile aggiungere il prodotto selezionato.",
                        icon:"error",
                        showCancelButton:false,
                        showConfirmButton:false,
                        timer:5000,
                    })
                }

            }
       })


      }
    },
    editQty(id, op){
     
     let item = this.orderList.rows.filter(function(el){
       return el._id == id
     })
     
     if (item){
       if (item.length>0){
         
         if (op == '+'){
           item[0].detail_item.qta++
         } else {
           item[0].detail_item.qta--
            if (item[0].detail_item.qta<=0){
            
             item[0].detail_item.qta = 1
            }
         }

         this.addTC(item[0].gcode, item[0].CodMarca, item[0].detail_item.qta)

       }
     }
     
   },       
    addTC(gcode,codMarca, qty){
      
      if (Object.keys(this.orderList).length>0){    
          if (this.orderList.header.orx){
              let data = { 
                  productCode: gcode,
                  userId: this.orderList.header.userId,
                  cuuid:this.orderList.header.momentCode,
                  orx: this.orderList.header.orx,
                  salesRoute: this.orderList.header.canalePerUtente,
                  qta: qty,
              }
              this.updateCompleteCart(data)
          }
      }
      
    },
    removeItem(id){
        if ( localStorage.getItem("orx") ) {
          Swal.fire(
            {
              title: this.$t("t-delete-confirm-setting-title"),
              text: this.$t("t-delete-confirm-setting-text"),
              icon: this.$t("t-delete-confirm-setting-icon"),
              showCancelButton: true,
              cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
              cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
              confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
              confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
            }
          ).then(() => {

            this.deleteItem(id, localStorage.getItem("orx"))
          })

        }
      },
    isData() {
      const plus = document.getElementsByClassName("plus");
      const minus = document.getElementsByClassName("minus");
      var product = document.getElementsByClassName("product");

      if (plus) {
        Array.prototype.forEach.call(plus, (e) => {
          e.addEventListener("click", (event) => {

            let par = event.target.closest(".input-step");
          
            par.getElementsByClassName("product-quantity")[0].value++;
            Array.prototype.forEach.call(product, () => {
              this.updateQuantity(event.target);
            });
          });
        });
      }

      if (minus) {
        Array.prototype.forEach.call(minus, (e) => {
          e.addEventListener("click", (event) => {
            let par = event.target.closest(".input-step");
              if(par.getElementsByClassName("product-quantity")[0].value > 0)
                par.getElementsByClassName("product-quantity")[0].value--;
              Array.prototype.forEach.call(product, () => {
              this.updateQuantity(event.target);
            });
          });
        });
      }
    },

    updateQuantity(quantityInput) {
      var productRow = quantityInput.closest(".product");
      var price;

      Array.prototype.forEach.call(
        productRow.getElementsByClassName("product-price"),
        (e) => {
          price = parseFloat(e.innerHTML);
        }
      );

      if (
        quantityInput.previousElementSibling &&
        quantityInput.previousElementSibling.classList.contains(
          "product-quantity"
        )
      ) {
        this.quantity = quantityInput.previousElementSibling.value;
      } else if (
        quantityInput.nextElementSibling &&
        quantityInput.nextElementSibling.classList.contains("product-quantity")
      ) {
        this.quantity = quantityInput.nextElementSibling.value;
      }
      var linePrice = price * this.quantity;
      /* Update line price display and recalc cart totals */
      Array.prototype.forEach.call(
        productRow.getElementsByClassName("product-line-price"),
        (e) => {
          e.innerHTML = linePrice.toFixed(2);
          this.recalculateCart();
        }
      );
    },
    recalculateCart() {
      var subtotal = 0;

      Array.prototype.forEach.call(
        document.getElementsByClassName("product"),
        (item) => {
          Array.prototype.forEach.call(
            item.getElementsByClassName("product-line-price"),
            (e) => {
              subtotal += parseFloat(e.innerHTML);
            }
          );
        }
      );

      /* Calculate totals */
      var tax = subtotal * this.taxRate;
      var discount = subtotal * this.discountRate;

      var shipping = subtotal > 0 ? this.shippingRate : 0;
      var total = subtotal + tax + shipping - discount;

      document.getElementById("cart-subtotal").innerHTML =
        this.currencySign + subtotal.toFixed(2);
      document.getElementById("cart-tax").innerHTML =
        this.currencySign + tax.toFixed(2);
      document.getElementById("cart-shipping").innerHTML =
        this.currencySign + shipping.toFixed(2);
      document.getElementById("cart-total").innerHTML =
        this.currencySign + total.toFixed(2);
      document.getElementById("cart-discount").innerHTML =
        "-" + this.currencySign + discount.toFixed(2);
    },
    updateStatus(state){
      if ( localStorage.getItem("orx") ) {
        axios.put(`${this.UrlServer}carts/status-update/${this.$route.params.orx}`, 
          {
            status:state,
          },
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          
          if (response.data){
            if (response.data.modifiedCount > 0) {
                Swal.fire( this.$t("t-msg-updated-status") , this.$t("t-msg-updated-content-status"), "success");
            } else {
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
            }
          }
         
          }).catch((error) => {
            console.log(error)
              if (error == "Error: Request failed with status code 401")
              {
                // this.alertSessionExp()
              } else {
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }

        });
       
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3">
      <div class="col-xl-8">
        <div class="row align-items-center gy-3 mb-3">
          <div class="col-sm">
            <div v-if="orderList">
              <template v-if="orderList.header">
                <h5 class="fs-14 mb-0">Ordine/Quotazione n. <strong>{{ orderList.header.orderNumber }}/{{ orderList.header.year }}</strong> del <strong>{{ convertFromUnix(orderList.header.unixDate) }}</strong></h5>
              </template>
            </div>
          </div>
          <div class="col-sm-auto">
            <!--
            <router-link
              to="/ecommerce/products"
              class="link-primary text-decoration-underline"
              >Aggiungi prodotti</router-link
            >-->
          </div>
        </div>
         
    <div v-for="item in orderList.rows" :key="item._id">
        <div id="content-area" class="card product">
           

          <div class="card-body">
            <div class="row gy-3">
              <div class="col-sm-auto">
                <div class="avatar-lg bg-light rounded p-1">
                  <img
                    src="@/assets/images/products/daikin-esempio.jpg"
                    alt=""
                    class="img-fluid d-block"
                  />
                </div>
              </div>
              <div class="col-sm">
                <h5 class="fs-14 text-truncate">
                  <router-link to="/ecommerce/product-details" class="text-dark"
                    >{{item.product[0].Descrizione}}</router-link
                  >
                </h5>
                <ul class="list-inline text-muted">
                  <li class="list-inline-item">
                    Codice Prodotto : <span class="fw-medium">{{item.gcode}}</span>
                  </li>
                  <li class="list-inline-item">
                    Codice EAN : <span class="fw-medium">{{item.detail_item.ean}}</span>
                  </li>
                </ul>

                <div class="input-step light">
                  <button type="button" class="minus shadow" @click="editQty(item._id,'–')">–</button>
                  <input
                    type="number"
                    class="product-quantity"
                    v-model="item.detail_item.qta"
                    min="0"
                    max="10000"
                    @blur="addTC(item.gcode, item.CodMarca,item.detail_item.qta)"
                  />
                  <button type="button" class="plus shadow" @click="editQty(item._id,'+')">+</button>
                </div>
              </div>
              <div class="col-sm-auto">
                <div class="text-lg-end">
                  <p class="text-muted mb-1">Prezzo Unitario:</p>
                  <h5 class="fs-14">
                    <template v-if="checkVar(item.detail_item.prezzoScontato2)=='number'">
                      &euro;<span id="ticket_price" class="product-price">{{formatPrice(item.detail_item.prezzoScontato2)}}</span>
                    </template>
                    <template v-else>
                      <template v-if="checkVar(item.detail_item.prezzoScontato1)=='number'">
                        &euro;<span id="ticket_price" class="product-price">{{formatPrice(item.detail_item.prezzoScontato1)}}</span>
                      </template>
                    </template>
                  </h5>
                </div>
              </div>
            </div>
          </div>
          
          <div class="card-footer">
            <div class="row align-items-center gy-3">
              <div class="col-sm">
                <div class="d-flex flex-wrap my-n1">
                  <div>
                    <a
                      href="javascript:void(0)"
                      class="d-block text-body p-1 px-2"
                      @click=removeItem(item._id)
                      ><i
                        class="ri-delete-bin-fill text-muted align-bottom me-1"
                      ></i>
                      Rimuovi</a
                    >
                  </div>
                  <div>
                    
                  </div>
                </div>
              </div>
              <div class="col-sm-auto">
                <div class="d-flex align-items-center gap-2 text-muted">
                  <div>Totale :</div>
                  <h5 class="fs-14 mb-0">
                    &euro;<span class="product-line-price">{{ formatPrice(item.detail_item.prezzo) }}</span>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        
        </div>
               <div>

        </div>
    </div> 


      </div>
      <!-- end col -->

      <div class="col-xl-4">
        <div class="sticky-side-div">
           <div class="card" v-if="this.$store.state.layout.layoutType=='horizontal'">
          <div class="card-header ">
            
            <div class="d-flex">
              <h5 class="card-title flex-grow-1 mb-0">Dettaglio documento</h5>
              <div class="flex-shrink-0">
                    <div class="mb-3" v-if="orderList.header">
                        
                        <label for="status-field" class="form-label">Come intendi salvare questo documento? <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="orderList.header.status" @change="updateStatus(orderList.header.status)">
                          <option value="quotation">Preventivo</option>
                          <option value="completed">Ordine</option>     
                        </select>
                   </div>
              </div>
            </div>
          </div>
          <div class="card-body">

          </div>
            </div>
           <div class="card" v-if="this.$store.state.layout.layoutType!=='horizontal'">
          <div class="card-header ">
            
            <div class="d-flex">
              <h5 class="card-title flex-grow-1 mb-0">Dettaglio ordinante</h5>
              <div class="flex-shrink-0">
                <a href="javascript:void(0);" class="link-secondary"
                  >Vedi profilo</a
                >
              </div>
            </div>
          </div>
          <div class="card-body">
            <ul class="list-unstyled mb-0 vstack gap-3" v-if="orderList.user">
              <li>
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <img
                      src="@/assets/images/users/avatar-3.jpg"
                      alt=""
                      class="avatar-sm rounded shadow"
                    />
                  </div>
                  <div class="flex-grow-1 ms-3" v-if="orderList.user">
                    <h6 class="fs-14 mb-1" v-if="orderList.user">{{ orderList.user.firstName }} {{  orderList.user.lastName  }} </h6>
                    <p class="text-muted mb-0">{{ orderList.user.ragSoc }}</p>
                  </div>
                </div>
              </li>
              <li>
                <i class="ri-mail-line me-2 align-middle text-muted fs-16"></i
                ><a :href="'mailto:'+ orderList.user.email">{{ orderList.user.email }}</a>
                
              </li>
              <li>
                <i class="ri-phone-line me-2 align-middle text-muted fs-16"></i
                >{{ orderList.user.phone}}
              </li>
            </ul>
          </div>
            </div>
          <div class="card">
            <div class="card-header border-bottom-dashed">
              <h5 class="card-title mb-0">Sommario </h5>
            </div>
            <div class="card-header bg-soft-light border-bottom-dashed">
              <div class="text-center">
                <h6 class="mb-2">
                  Hai un codice promozionale ?
                </h6>
              </div>
              <div class="hstack gap-3 px-3 mx-n3">
                <input
                  class="form-control me-auto"
                  type="text"
                  placeholder="INSERISCI CODICE PROMO VALIDO"
                  aria-label="Inserisci codice qui..."
                />
                <button type="button" class="btn btn-success w-xs">
                  Applica
                </button>
              </div>
            </div>
            <div class="card-body pt-2">
              <div class="table-responsive">
                <table class="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td>SubTotale :</td>
                      <td class="text-end" id="cart-subtotal">&euro; -</td>
                    </tr>
                    <tr>
                      <td>
                        Sconto <span class="text-muted"></span> :
                      </td>
                      <td class="text-end" id="cart-discount">- &euro; -</td>
                    </tr>
<!--                <tr>
                      <td>Shipping Charge :</td>
                      <td class="text-end" id="cart-shipping">&euro; -</td>
                    </tr>
                    <tr>
                      <td>Estimated Tax (12.5%) :</td>
                      <td class="text-end" id="cart-tax">&euro; 44.99</td>
                    </tr>-->
                    <tr class="table-active">
                      <th>Totale (EUR) :</th>
                      <td class="text-end">
                        <span class="fw-semibold" id="cart-total">
                         &euro;<span class="product-line-price">{{ formatPrice(orderList.totalPrice) }}</span>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-responsive -->
            </div>
          </div>

          <div class="card">
            <div class="card-header border-bottom-dashed">
              <h5 class="card-title mb-0">Dettaglio e stampa documento </h5>
            </div>
            <div class="card-header bg-soft-light border-bottom-dashed">
              <div class="text-center">
                <h6 class="mb-2">
                  
                </h6>
              </div>
              <div class="hstack gap-3 px-3 mx-n3">

                <button type="button" class="btn btn-danger" @click="$router.push('/quote/details/'+$route.params.orx + '/print')">
                  <span class="bx bx-printer" ></span>
                  Stampa preventivo
                </button>
              </div>
            </div>
            <div class="card-body pt-2">
            </div>
        </div>

          
        </div>
        <!-- end stickey -->
      </div>
    </div>
    <!-- end row -->


    <!-- /.modal -->
  </Layout>
 
</template>
